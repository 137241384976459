const Config = {
  appVersion: "1.0",
  appAuthStorageKey: "flt-app-auth",
  defaultPageTitle: "Fast Lane Traffic",
  apiUrl: process.env.REACT_APP_API_URL,
  clientId: "9965c262b3964704b105e29340271603",
  gtmId: process.env.REACT_APP_GTM_ID,
};

export default Config;
